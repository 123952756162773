@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.awsui awsui-toggle {
    display: inline-block;
    margin-left: 10px;
}

.preloaded-lab-status {
    color: awsui.$color-text-status-success;
}

.failed-lab-status {
    color: awsui.$color-text-status-error;
}
