.page-wrapper {
    padding: 2rem 4rem;
    max-width: 1020px;
    margin: 0 auto;
    width: 100%;
}

.page-wrapper-wide {
    padding: 2rem 4rem;
    max-width: 1920px;
    margin: 0 auto;
}
