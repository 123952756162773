@import '@/styles/common-mixins';

.lab-main {
    display: flex;
    & > *:first-child {
        flex: 0 0 180px;
    }
}

[data-testid='manage-labs__side-navigation'] a {
    @include truncated-link();
}
