@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.jam-detail__container {
    dd {
        margin-left: 0.1em;
    }
}

.border-top {
    margin-top: 0px;
    padding-top: 8px;
    border-top: 1px solid awsui.$color-border-divider-default;
}
