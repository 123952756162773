@mixin opposites {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

@mixin truncated-link {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
