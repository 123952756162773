// TODO: use design tokens instead of hardcoded color values. VS merge issues blocking this at present

.footer {
    background-color: #232f3e;
    padding: 0.75rem 1.5rem;
    z-index: 1000;
    position: sticky;
    bottom: 0px;
}

.footer__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 976px;
    text-align: center;
}

.footer__body > * {
    padding: 0 !important;
}

.footer__body > p {
    color: #fafafa !important;
}

.footer__body__links {
    margin: 0;
}

.footer__body__links > * {
    display: inline-block;
}

.footer__body__links > * a {
    color: #44b9d6 !important;
}

.footer__body__links > * + * {
    margin-left: 20px;
    position: relative;
    padding-top: 0 !important;
}

.footer__body__links > * + *:before {
    content: '';
    height: 25px;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background-color: #414750;
    position: absolute;
}

@media (min-width: 659px) {
    .footer__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer__body__links {
        text-align: left;
    }
}

@media (min-width: 1201px) {
    .footer__body {
        max-width: 1248px;
    }
}
