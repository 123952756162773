/*
    TODO: temp fix for toggle button not sticking to the right side of the card
    please remove after migrating LabList component to Polaris
*/
.lab-list {
    list-style-type: none;
    padding-left: 0;
}

.lab-list > * + * {
    margin-top: 24px;
}

.lab-list .actions {
    float: right;
}
