.providers-page {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.providers-page > * {
    width: 80%;
    max-width: 550px;
}

.providers-list {
    list-style-type: none;
    padding: 0;
}

.providers-list li button {
    width: 100%;
    padding: 20px !important;
}

.providers-list li + li {
    margin-top: 12px;
}
