.class-detail__flex-between {
    display: flex;
    justify-content: space-between;

    &:after {
        content: '';
        clear: both;
        display: table;
    }
}

.widget-container__child {
    margin-left: 1em;
}

.class-detail__virtual-url-icon-wrapper {
    width: 3rem;
    float: left;
}

.awsui .class-detail__action-btn button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
