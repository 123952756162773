.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.jam-link {
    max-width: 70vh;
    text-overflow: ellipsis;
}
